/* eslint-disable camelcase */
export const ROOT = '/api' 
//export const ROOT = 'https://test.ezgolf.vn/api'

// Site
export const LOGIN = '/RAT01/Process' // 'https://testgolf.ezfolio.net/token'
export const GET_TOKEN = '/RAU01/Process'
export const SWITCH_SITE = '/USI01/Process'
export const API_UAT01 = '/UAT01/Process'
export const api_RBAAR = '/RBAAR/Process'
export const API_RCS01 = '/RCS01/Process'
// email
export const API_IEM01 = '/IEM01/Process'
export const API_IEM02 = '/IEM02/Process'
// User-setting
export const API_RRO01 = '/RRO01/Process'
export const API_URO01 = '/URO01/Process'
export const API_RCF00 = '/RCF00/Process'

// SGMS
export const SGMS_URL = '/sgms/api'
export const API_BG_UBK01S = '/BG_IBK01S/Process'

// hotel
export const GET_LIST_HOTEL = '/hotel/RHT01/Process'

// member
export const API_RME01F = '/member/RME01F/Process'
export const GET_PACKAGE_RETAIL_DETAIL = '/member/RME04A/Process'
export const GET_LIST_MEMBER_CARD_API = '/member/RMEC01/Process'
export const CREATE_MEMBER_CARD_API = '/member/IMEC01/Process'
export const UPDATE_MEMBER_CARD_API = '/member/UMEC01/Process'
export const DELETE_MEMBER_CARD_API = '/member/DMEC01/Process'
export const DETAIL_MEMBER_CARD_API = '/member/RMEC02/Process'
export const ACTIVE_MEMBER_CARD_API = '/member/UMEC03/Process'
export const DEACTIVE_MEMBER_CARD_API = '/member/UMEC02/Process'
export const GET_LIST_MEMBER_API = '/member/RME01/Process'
export const CREATE_MEMBER_API = '/member/IME01/Process'
export const API_IME02 = '/member/IME02/Process'
export const UPDATE_MEMBER_API = '/member/UME01/Process'
export const DELETE_MEMBER_API = '/member/DME01/Process'
export const DETAIL_MEMBER_API = '/member/RME02/Process'
export const ACTIVE_MEMBER_API = '/member/UME03/Process'
export const DEACTIVE_MEMBER_API = '/member/UME02/Process'
export const MERGE_MEMBER_API = '/member/MME01/Process'
export const CHECK_MEMBER_DUP_VGA = '/member/RME07/Process'
export const GET_HISTORY_API = '/member/RMEH01/Process'
export const API_RMEH02 = '/member/RMEH02/process'
export const GET_LIST_COMBO_API = '/member/RME04/process'
export const API_RME05 = '/member/RME05/Process'
export const GET_COMPANY = '/member/RCL01/Process'
export const GET_COMPANIES_API = '/member/RCL01/process'
export const SOURCES_LIST_API = '/member/RCL01/Process'
export const GET_COUNTRIES_API = '/member/RNA01/process'
export const GET_LIST_NATIONAL_API = '/member/RNA01/Process'
export const GET_LIST_SOURCE = '/member/RSO01/Process'
export const RESOURCES_API = '/member/RSO01/Process'

// service
export const GET_CURRENCY = '/service/RCU01/Process'
export const GET_LIST_PAYMENT_METHOD = '/service/RPF01/Process'
export const API_RPF02 = '/service/RPF02/Process'
export const AR_RPF02 = '/service/RPF02/process'
export const GET_LIST_REASON_DISCOUNT = '/service/RRC01/Process'
export const GET_LIST_TRANSACTION = '/service/RSV01/Process'
export const GET_LIST_SERIVCE = '/service/RSV02/Process'
export const GET_LIST_SERVICES = '/service/RSV02/Process'
export const API_RSVAR = '/service/RSVAR/process'
export const GET_LIST_TRANSACTION_CODE = '/service/RTC01/Process'

// reports
export const api_RF01 = '/RF01/Process'

// course
export const GET_LIST_COURSE = '/course/RCO01/Process'

export const ADD_NEW_COURSE = '/course/ICO01/Process'
export const DELETE_COURSE = '/course/DCO01/Process'
export const GET_COURSE_DETAIL = '/course/RCO02/Process'
export const UPDATE_COURSE_DETAIL = '/course/UCO01/Process'
export const ACTIVE_COURSE = '/course/UCO03/Process'
export const INACTIVE_COURSE = '/course/UCO02/Process'

export const GET_LIST_TEE = '/course/RTE01/Process'
export const GET_TEE_DETAIL = '/course/RTE02/Process'
export const ADD_NEW_TEE = '/course/ITE01/Process'
export const ACTIVE_TEE = '/course/UTE03/Process'
export const INACTIVE_TEE = '/course/UTE02/Process'
export const DELETE_TEE = '/course/DTE01/Process'
export const UPDATE_TEE = '/course/UTE01/Process'

// Driving Range
export const GET_LIST_DRIVING_RANGE = '/course/RDR01/Process'
export const ADD_NEW_DRIVING_RANGE = '/course/IDR01/Process'
export const GET_DETAIL_DRIVING_RANGE = '/course/RDR02/Process'
export const UPDATE_DRIVING_RANGE = '/course/UDR01/Process'
export const DELETE_UPDATE_DRIVING_RANGE = '/course/DDR01/Process'
export const ACTIVE_DRIVING_RANGE = '/course/UDR03/Process'
export const DEACTIVE_DRIVING_RANGE = '/course/UDR02/Process'

// Booking Driving Range

export const API_UPM08 = '/course/UPM08/Process'
export const GET_LIST_BOOKING_DRIVING_RANGE = '/course/RBKD01/Process'
export const CREATE_BOOKING_DRIVING_RANGE = '/course/IBKD01/Process'
export const GET_DETAIL_BOOKING_DRIVING_RANGE = '/course/RBKD2A/Process'
export const GET_DETAIL_BOOKING_DRIVING_RANGE_2B = '/course/RBKD2B/Process'
export const RBKD2C = '/course/RBKD2C/Process'
export const UPDATE_BOOKING_DR_SERVICE = '/course/UBKD3/Process'
export const API_RBKD2D = '/course/RBKD2D/Process'
export const API_IPM03 = '/course/IPM03/Process'
export const API_RPM03 = '/course/RPM03/Process'
export const API_DPM03 = '/course/DPM03/Process'
export const API_RPM04 = '/course/RPM04/Process'
export const API_IPM04 = '/course/IPM04/Process'
export const API_DPM04 = '/course/DPM04/Process'
export const API_UBKD9 = '/course/UBKD9/Process'
export const API_IBKD02 = '/course/IBKD02/Process'
export const API_UBKD02 = '/course/UBKD02/Process'
export const API_UBKD01 = '/course/UBKD01/Process'
export const API_ICID01 = '/course/ICID01/Process'
export const API_ICID02 = '/course/ICID02/Process'
export const API_DBKD01 = '/course/DBKD01/Process'
export const API_DBKD02 = '/course/DBKD02/Process'
export const API_RIV01 = '/course/RIV01/Process'
export const API_RIV04 = '/course/RIV04/Process'
export const API_RIV02 = '/course/RIV02/Process'

export const API_DPM08 = '/course/DPM08/Process'
export const API_VC_RV04 = '/ar/VC_RV04/Process'
export const API_VC_RV05 = '/ar/VC_RV05/Process'
export const API_ICID03 = '/course/ICID03/Process'
export const API_RHS02 = '/course/RHS02/Process'

// Common
export const GET_LIST_HOLE = '/course/RHO01/Process'
export const COMMON = '/course/RCOM01/Process'
export const GET_LANGUAGES = '/course/RCOM02/Process'
export const LIST_LANGUAGE = '/course/RLA01/Process'

export const GET_DETAIL_SPECIAL_DAY = '/course/RSD02/Process'
export const GET_LIST_SPECIAL_DAY = '/course/RSD01/Process'
export const ADD_NEW_SPECIAL_DAY = '/course/ISD01/Process'
export const UPDATE_SPECIAL_DAY = '/course/USD01/Process'
export const DELETE_SPECIAL_DAY = '/course/DSD01/Process'

// Caddy
export const GET_LIST_CADDY_GROUP = '/course/RCAG01/Process'
export const GET_DETAIL_CADDY_GROUP = '/course/RCAG02/Process'
export const UPDATE_CADDY_GROUP = '/course/UCAG01/Process'
export const ADD_NEW_CADDY_GROUP = '/course/ICAG01/Process'
export const DELETE_CADDY_GROUP = '/course/DCAG01/Process'
export const ACTIVE_CADDY_GROUP = '/course/UCAG03/Process'
export const DEACTIVE_CADDY_GROUP = '/course/UCAG02/Process'

export const GET_LIST_CADDY = '/course/RCA01/Process'
export const ADD_NEW_CADDY = '/course/ICA01/Process'
export const GET_DETAIL_CADDY = '/course/RCA02/Process'
export const UPDATE_CADDY = '/course/UCA01/Process'
export const ACTIVE_CADDY = '/course/UCA03/Process'
export const DEACTIVE_CADDY = '/course/UCA02/Process'
export const DELETE_CADDY = '/course/DCA01/Process'
export const SEARCH_CADDY = '/course/RCA03/Process'
export const API_UCA04 = '/course/UCA04/Process'
export const API_UCAC05 = '/course/UCAC05/Process'

// Rate
export const GET_RATE_LIST = '/course/RRA01/Process'
export const GET_RATE_PARENT = '/course/RRA03/Process'
export const ADD_NEW_MASTER_RATE = '/course/IRA01/Process'
export const UPDATE_MASTER_RATE = '/course/URA03/Process'
export const ADD_NEW_RATE_COURSE = '/course/IRA02/Process'
export const GET_DETAIL_RATE = '/course/RRA02/Process'
export const GET_DETAIL_RATE_COURSE = '/course/RRA02A/Process'
export const UPDATE_RATE_COURSE = '/course/URA04/Process'
export const DELETE_RATE_COURSE = '/course/DRA02/Process'
export const DISABLE_RATE = '/course/URA01/Process'
export const ENABLE_RATE = '/course/URA02/Process'
export const DELETE_RATE = '/course/DRA01/Process'

export const ADD_NEW_RATE_ADDITIONAL_FEE = '/course/IRA03/Process'
export const UPDATE_RATE_ADDITIONAL_FEE = '/course/URA05/Process'
export const DELETE_RATE_ADDITONAL_FEE = '/course/DRA03/Process'
export const API_RRA07 = '/course/RRA07/Process'
export const COPY_RATE_IRA04 = '/course/IRA04/Process'

// Package
export const GET_PACKAGE_LIST = '/course/RPA01/Process'
export const ADD_MEW_MASTER_PACKAGE = '/course/IPA01/Process'
export const GET_DETAIL_PACKAGE = '/course/RPA02/Process'
export const DELETE_PACKAGE = '/course/DPA01/Process'
export const UPDATE_MASTER_PACKAGE = '/course/UPA03/Process'
export const ENABLE_PACKAGE = '/course/UPA02/Process'
export const DISABLE_PACKAGE = '/course/UPA01/Process'
export const ADD_NEW_PACKAGE_COURSE = '/course/IPA02/Process'
export const DELETE_PACKAGE_COURSE = '/course/DPA02/Process'
export const UPDATE_PACKAGE_COURSE = '/course/UPA04/Process'
export const DELETE_PACKAGE_ADDITIONAL_FEE = '/course/DPA03/Process'
export const ADD_NEW_PACKAGE_ADDITIONAL_FEE = '/course/IPA03/Process'
export const UPDATE_PACKAGE_ADDITIONAL_FEE = '/course/UPA05/Process'
export const SET_DEFAULT_PACKAGE_COURSE = '/course/UPA06/Process'
export const ADD_PACKAGE_RETAIL = '/course/IBKRT1/Process'
export const UPDATE_PACKAGE_RETAIL = '/course/UBKRT1/Process'

// Package Driving Range
export const GET_LIST_PACKAGE_DRIVING_RANGE = '/course/RPADR01/Process'
export const GET_DETAIL_PACKAGE_DRIVING_RANGE1 = '/course/RPADR01/Process'
export const GET_DETAIL_PACKAGE_DRIVING_RANGE2 = '/course/RPADR02/Process'
export const ADD_PACKAGE_DRIVING_RANGE = '/course/IPADR01/Process'
export const UPDATE_PACKAGE_DRIVING_RANGE = '/course/UPADR03/Process'
export const DELETE_PACKAGE_DRIVING_RANGE = '/course/DPADR01/Process'
export const STOP_PACKAGE_DRIVING_RANGE = '/course/UPADR01/Process'
export const ENABLE_PACKAGE_DRIVING_RANGE = '/course/UPADR02/Process'
export const API_UPM03 = '/course/UPM03/Process'
export const API_DPM10 = '/course/DPM10/Process'

// golf class

export const GET_LIST_GOLF_CLASS = '/course/RGC01/Process'
export const ADD_NEW_GOLF_CLASS = '/course/IGC01/Process'
export const UPDATE_GOLF_CLASS = '/course/UGC01/Process'
export const DEACTIVE_GOLF_CLASS = '/course/UGC02/Process'
export const ACTIVE_GOLF_CLASS = '/course/UGC03/Process'
export const DELETE_GOLF_CLASS = '/course/DGC01/Process'

// caddy calendar
export const API_RCAC01 = '/course/RCAC01/Process'
export const API_UCAC01 = '/course/UCAC01/Process'
export const API_RCAC02 = '/course/RCAC02/Process'
export const API_RCA03 = '/course/RCA03/Process'
export const API_RCA05 = '/course/RCA05/Process'
export const API_ICAC01 = '/course/ICAC01/Process'
export const API_DCAC01 = '/course/DCAC01/Process'
export const API_RCAC03 = '/course/RCAC03/Process'
export const API_UCAC02 = '/course/UCAC02/Process'
export const API_UCAC04 = '/course/UCAC04/Process'
export const API_RCWS01 = '/course/RCWS01/Process'

// starter
export const API_RBKSA1 = '/course/RBKSA1/Process'
export const API_UBKSA1 = '/course/ICI01/Process'
export const API_UBKSA2 = '/course/ICI02/Process'
export const API_RBKSA2 = '/course/RBKSA2/Process'
export const API_UBK02B = '/course/UBK02B/Process'
export const API_UCI01 = '/course/UCI01/Process'
export const API_IBKSA1 = '/course/IBKSA1/Process'
export const API_RBKSA3 = '/course/RBKSA3/Process'

// print
export const API_RBK08 = '/course/RBK08/Process'
export const API_RBKD04 = '/course/RBKD04/Process'

// caddy evaluation
export const API_RCAE01 = '/course/RCAE01/Process'
export const API_UCAE01 = '/course/UCAE01/Process'
export const API_RCAV01 = '/course/RCAV01/Process'
export const API_UCAV01 = '/course/UCAV01/Process'
export const API_ICAE01A = '/course/ICAE01A/Process'

// tee sheet
export const API_RTT01 = '/course/RTT01/Process'
export const API_RTE01 = '/course/RTE01/Process'
export const API_ITT01 = '/course/ITT01/Process'
export const API_UTT01 = '/course/UTT01/Process'
export const API_UTT02 = '/course/UTT02/Process'
export const API_DTT01 = '/course/DTT01/Process'
export const API_RTT02 = '/course/RTT02/Process'

// Subscription
export const GET_LIST_SUBSCRIPTION_API = '/member/RSU03/process'
export const GET_DETAIL_SUBSCRIPTION_API = '/member/RSU02/process'
export const UPDATE_SUBSCRIPTION_API = '/member/USU01/process'
export const DELETE_SUBSCRIPTION_API = '/member/DSU01/process'
export const CREATE_SUBSCRIPTION_API = '/member/ISU01/process'
export const BANS_SUBSCRIPTION_API = '/member/USU02/process'
export const UNBANS_SUBSCRIPTION_API = '/member/USU03/process'
export const TRANSFER_SUBSCRIPTION_API = '/member/USU04/process'

// booking
export const GET_LIST_TEE_BOOKING_API = '/course/RTB01/Process'
export const GET_LIST_BOOKING_API = '/course/RTS01/Process'
export const GET_LIST_GROUP_API = '/course/RBK03/Process'
export const CREATE_BOOKING_API = '/course/IBK01/Process'
export const ADD_MEMBER_API = '/course/IBK02/Process'
export const SPLITGROUP_API = '/course/IBK01A/Process' // tách đoàn
export const GOLF_CLASSES_API = '/course/RGC01/Process'
export const RATE_CODE_API = '/course/RRA04/Process'
export const DETAIL_BOOKING_2A_API = '/course/RBK02A/Process'
export const DETAIL_BOOKING_2B_API = '/course/RBK02B/Process'
export const DETAIL_BOOKING_2C_API = '/course/RBK02C/Process'
export const API_RBK02D = '/course/RBK02D/Process'
export const CHECK_IN = '/course/ICI01/Process'
export const COMBINE_GROUP_API = '/course/UBK06/Process'
export const UPDATE_RATE_CODE_API = '/course/UBK03/Process'
export const LIST_SERVICES_API = '/course/RBK05/Process'
export const LIST_CADDY = '/course/RCA04/Process'
export const BOOKING_UPDATE_CADDY = '/course/UBK05/Process'
export const UPDATE_BOOKING_INFO = '/course/UBK01/Process'
export const GET_CONFIG_API = '/course/RCOM01/Process'
export const NO_SHOW = '/course/UBK08/Process'
export const CHECK_OUT = '/course/RBK02C/Process'
export const GET_LIST_PAYMENT = '/course/RPM01/Process'
export const CREATE_PAYMENT_API = '/course/IPM01/Process'
export const CREATE_PAYMENT_DETAIL_API = '/course/IPM02/Process'
export const GET_LIST_PAYMENT_DETAIL = '/course/RPM02/Process'
export const UPDATE_BOOKING_SERVICE = '/course/UBK03C/Process'
export const DELETE_PAYMENT = '/course/DPM01/Process'
export const DELETE_PAYMENT_DETAIL = '/course/DPM02/Process'
export const PUBLISH_VIEW_EINVOICE_COURSE = '/course/IPM07/Process'

export const UPDATE_TEE_TIME_FOR_BOOKING = '/course/UBK02/Process'
export const UPDATE_LOCKER_AND_NOTE_FOR_BOOKING = '/course/UBK10/Process'
export const UPDATE_INFO_EINVOICE_BOOKING_DETAIL = '/course/UBK20/Process'
export const API_UBK09 = '/course/UBK09/Process'
export const GET_BOOKING_RETAIL = '/course/RBKRT2/Process'
export const CREATE_PAYMENT_RETAIL = '/course/IPM05/Process'
export const ADD_PAYMENT_RETAIL = '/course/IPM06/Process'
export const GET_PAYMENT_RETAIL = '/course/RPM06/Process'
export const API_RBK09 = '/course/RBK09/Process'
export const API_UBK12 = '/course/UBK12/Process'
export const API_UBK13 = '/course/UBK13/Process'
export const API_UBK11 = '/course/UBK11/Process'
export const API_DBK02 = '/course/DBK02/Process'
export const API_DBK01 = '/course/DBK01/Process'
export const API_UPM02 = '/course/UPM02/Process'
export const API_ICI02 = '/course/ICI02/Process'
export const API_RPM02D = '/course/RPM02D/Process'
export const API_DPM02D = '/course/DPM02D/Process'
export const API_IPM02D = '/course/IPM02D/Process'
export const API_UPM02D = '/course/UPM02D/Process'
export const API_DPM07 = '/course/DPM07/Process'

export const API_UPM01 = '/course/UPM01/Process'
export const API_RTE03 = '/course/RTE03/Process'
export const API_RTB02 = '/course/RTB02/Process'
export const API_RBK10 = '/course/RBK10/Process'
export const API_RPM02M = '/course/RPM02M/Process'
export const API_IPM02R = '/course/IPM02R/Process'
export const API_DPM09 = '/course/DPM09/Process'
export const API_RHS01 = '/course/RHS01/Process'
export const API_RRA05 = '/course/RRA05/Process'
export const API_ICI03 = '/course/ICI03/Process'
export const API_VC_RV03 = '/ar/VC_RV03/Process'
export const API_UBK10 = '/course/UBK10/Process'
export const API_UPM06 = '/course/UPM06/Process'
export const API_RBK06 = '/course/RBK06/Process'
export const API_VC_RV06 = '/ar/VC_RV06/Process'
export const API_RRA04A = '/course/RRA04A/Process'
export const API_UBK15 = '/course/UBK15/Process'
export const API_RBK12 = '/course/RBK12/Process'
export const API_RCL03 = '/member/RCL03/Process'
export const API_UPM07 = '/course/UPM07/Process'
export const API_RPM01F = '/course/RPM01F/Process'
export const API_RBK13 = '/course/RBK13/Process'
export const API_IBKR01 = '/course/IBKR01/Process'
export const API_DBKR01 = '/course/DBKR01/Process'
export const API_RBKR01 = '/course/RBKR01/Process'
export const API_UBK16 = '/course/UBK16/Process'
export const API_RBK15 = '/course/RBK15/Process'
export const API_UBK17 = '/course/UBK17/Process'

// booking retail
export const API_RBKRT1 = '/course/RBKRT1/Process'

export const API_UBK04 = '/course/UBK04/Process'
export const API_UBK07 = '/course/UBK07/Process'
export const API_UPM04 = '/course/UPM04/Process'
export const API_DPM06 = '/course/DPM06/Process'
export const API_ICIR03 = '/course/ICIR03/Process'
export const API_DBKRT01 = '/course/DBKRT01/Process'
export const API_ICIR02 = '/course/ICIR02/Process'
export const API_RHS03 = '/course/RHS03/Process'
export const API_UBKRT2 = '/course/UBKRT2/Process'

// report URL
export const REPORT_URL = `/rp${ROOT}/reports/`
export const API_RRPDF = `/rp${ROOT}/RRPDF/Process`
// config
export const api_RCF01 = '/course/RCF01/Process'
export const api_RCF02 = '/course/RCF02/Process'
export const api_RCFV01 = '/course/RCFV01/Process'
export const api_RCFV02 = '/course/RCFV02/Process'
export const api_ICF01 = '/course/ICF01/Process'
export const api_UCF01 = '/course/UCF01/Process'
export const api_ICFV01 = '/course/ICFV01/Process'
export const api_UCFV01 = '/course/UCFV01/Process'
export const api_DCFV01 = '/course/DCFV01/Process'
export const api_DCF01 = '/course/DCF01/Process'
export const api_DCF02 = '/course/DCF02/Process'

// config report
export const api_RRCF01 = '/course/RRCF01/Process'
export const api_RRCF02 = '/course/RRCF02/Process'
export const api_RRCFV01 = '/course/RRCFV01/Process'
export const api_RRCFV02 = '/course/RRCFV02/Process'
export const api_IRCF01 = '/course/IRCF01/Process'
export const api_URCF01 = '/course/URCF01/Process'
export const api_IRCFV01 = '/course/IRCFV01/Process'
export const api_URCFV01 = '/course/URCFV01/Process'
export const api_DRCFV01 = '/course/DRCFV01/Process'
export const api_DRCF01 = '/course/DRCF01/Process'
export const api_DRCF02 = '/course/DRCF02/Process'
export const api_IRCF02 = '/course/IRCF02/Process'
export const api_RRCF03 = '/course/RRCF03/Process'
// Invoice
export const API_RPMI01 = '/course/RPMI01/Process'
export const API_RPMI02 = '/course/RPMI02/Process'
export const API_IIVAT01 = '/course/IIVAT01/Process'
export const API_RIVAT01A = '/course/RIVAT01A/Process'
export const API_RIVAT02 = '/course/RIVAT02/Process'
export const API_UIVAT02 = '/course/UIVAT02/Process'
export const API_RIVAT01 = '/course/RIVAT01/Process'
export const API_DIVAT01 = '/course/DIVAT01/Process'
export const API_UIVAT01 = '/course/UIVAT01/Process'
export const API_UIVAT03 = '/course/UIVAT03/Process'
export const API_UIVAT04 = '/course/UIVAT04/Process'
export const API_DICF01 = '/course/DICF01/Process'
export const API_IICF01 = '/course/IICF01/Process'
export const API_RICF01 = '/course/RICF01/Process'
export const API_UICF01 = '/course/UICF01/Process'

// EInvocie
export const API_IIVAT02 = '/course/IIVAT02/Process'
export const API_IIVAT03 = '/course/IIVAT03/Process'
export const API_RIVAT03 = '/course/RIVAT03/Process'
export const API_DIVAT03 = '/course/DIVAT03/Process'

// RevenueCenter
export const API_IRCE01 = '/course/IRCE01/Process'
export const API_RRCE01 = '/course/RRCE01/Process'
export const API_URCE01 = '/course/URCE01/Process'
export const API_RRCE02 = '/course/RRCE02/Process'
export const API_DRCE01 = '/course/DRCE01/Process'
export const API_RRCZ01 = '/course/RRCZ01/Process'
export const API_IRCZ01 = '/course/IRCZ01/Process'
export const API_URCZ01 = '/course/URCZ01/Process'
export const API_URCP01 = '/course/URCP01/Process'
export const API_RRCC00 = '/course/RRCC00/Process'
export const API_RRCC00P = '/course/RRCC00P/Process'
export const API_RRCC00D = '/course/RRCC00D/Process'
export const API_RLPD01 = '/course/RLPD01/Process'
export const API_URCC01 = '/course/URCC01/Process'
export const API_URGC01 = '/course/URGC01/Process'
export const API_URCT01 = '/course/URCT01/Process'
export const API_URCTC1 = '/course/URCTC1/Process'
export const API_URCSN1 = '/course/URCSN1/Process'
export const API_RCS03 = '/course/RRCS03/Process'
export const API_RCT04 = '/course/RRCT04/Process'
export const API_URCD01 = '/course/URCD01/Process'
export const API_URCR01 = '/course/URCR01/Process'
export const API_RRCC00E = '/course/RRCC00E/Process'
export const API_URCZ02 = '/course/URCZ02/Process'
export const API_RRCS04 = '/course/RRCS04/Process'
export const API_RRCE05 = '/course/RRCE05/Process'
// User

export const API_DIVAT02 = '/course/DIVAT02/Process'

// wating list
export const API_UBKWL1 = '/course/UBKWL1/Process'
export const API_RBKWL1 = '/course/RBKWL1/Process'
export const API_DBKWL1 = '/course/DBKWL1/Process'
// course locker
export const API_LK_RLKA01 = '/course/LK_RLK01/Process'
export const API_LK_ILKA01 = '/course/LK_ILKA01/Process'
export const API_LK_ULKA01 = '/course/LK_ULKA01/Process'
export const API_LK_DLKA01 = '/course/LK_DLKA01/Process'
export const API_LK_ILK01 = '/course/LK_ILK01/Process'
export const API_LK_ULK01 = '/course/LK_ULK01/Process'
export const API_LK_DLK01 = '/course/LK_DLK01/Process'
export const API_LK_ULK02 = '/course/LK_ULK02/Process'
export const API_LK_RLA01 = '/course/LK_RLA01/Process'
export const API_LK_RLAR01 = '/course/LK_RLAR01/Process'
export const API_LK_ULA01 = '/course/LK_ULA01/Process'
export const API_LK_RLK02 = '/course/LK_RLK02/Process'
export const API_LK_ULKA02 = '/course/LK_ULKA02/Process'
export const API_LK_ULKA03 = '/course/LK_ULKA03/Process'
export const API_LK_ULK03 = '/course/LK_ULK03/Process'

// tour
export const API_TOUR_RTO01 = '/tour/TOUR_RTO01/Process'
export const API_TOUR_ITO01 = '/tour/TOUR_ITO01/Process'
export const API_TOUR_UTO01 = '/tour/TOUR_UTO01/Process'
export const API_TOUR_DTO01 = '/tour/TOUR_DTO01/Process'
export const API_TOUR_IGO01 = '/tour/TOUR_IGO01/Process'
export const API_TOUR_UGO01 = '/tour/TOUR_UGO01/Process'
export const API_TOUR_DGO01 = '/tour/TOUR_DGO01/Process'
export const API_TOUR_IGR01 = '/tour/TOUR_IGR01/Process'
export const API_TOUR_RGO01 = '/tour/TOUR_RGO01/Process'
export const API_TOUR_RGR01 = '/tour/TOUR_RGR01/Process'
export const API_TOUR_DGR01 = '/tour/TOUR_DGR01/Process'
export const API_TOUR_UGR01 = '/tour/TOUR_UGR01/Process'
export const API_TOUR_UGR04 = '/tour/TOUR_UGR04/Process'
export const API_TOUR_UGR02 = '/tour/TOUR_UGR02/Process'
export const API_TOUR_UGR03 = '/tour/TOUR_UGR03/Process'
export const API_TOUR_ITE01 = '/tour/TOUR_ITE01/Process'
export const API_TOUR_RTE01 = '/tour/TOUR_RTE01/Process'
export const API_TOUR_UTE02 = '/tour/TOUR_UTE02/Process'
export const API_TOUR_DTE01 = '/tour/TOUR_DTE01/Process'
export const API_TOUR_UPA02 = '/tour/TOUR_UPA02/Process'
export const API_TOUR_UPA03 = '/tour/TOUR_UPA03/Process'
export const API_TOUR_UTE01 = '/tour/TOUR_UTE01/Process'
export const API_TOUR_IBK01 = '/tour/TOUR_IBK01/Process'
export const API_TOUR_UGO02 = '/tour/TOUR_UGO02/Process'
export const API_TOUR_UBK10 = '/tour/TOUR_UBK10/Process'

// Short Cut Key
export const F1 = 112
export const F2 = 113
export const F3 = 114
export const F4 = 115
export const F5 = 116
export const F6 = 117
export const F7 = 118
export const F8 = 119
export const F9 = 120
export const F10 = 121
export const F11 = 122
export const F12 = 123
export const A = 65
export const F = 70
export const S = 83
export const P = 80
export const R = 82
export const Z = 90
export const G = 71
export const UP = 38
export const DOWN = 40
export const Key1 = 49
export const Key2 = 50
export const Key3 = 51
export const Key4 = 52
export const Key5 = 53
export const Key6 = 54
export const Key7 = 55
export const Key8 = 56
export const Key9 = 57
export const subKey1 = 97
export const subKey2 = 98
export const subKey3 = 99
export const subKey4 = 100
export const subKey5 = 101
export const subKey6 = 102
export const subKey7 = 103
export const subKey8 = 104
export const subKey9 = 105
